import React, {ChangeEvent} from 'react';
import Loader from "./Loader";
import InputText from "./InputText";
import user from "../types/user";
import PersonCard from "./PersonCard";
import BackButton from "./BackButton";
import LoadingIndicator from "./LoadingIndicator";
import ApiContext from "./Context/ApiContext";
import UserRoleService from "../services/UserRoleService";
import UserStatusService from "../services/UserStatusService";
import {company} from "../types/company";
import Select from "./Select/Select";
import {ValueType} from "react-select/src/types";
import {OptionType} from "./Select/SelectTypes";
import Autosuggest, {autosuggestSuggestion} from "./Autosuggest";

type userManagementProps = {
  user: user | undefined
}

type userManagementState = {
  users: user[]
  loaded: boolean
  loadedMore: boolean
  statusFilterOptions: OptionType[]
  statusFilterValue: OptionType | null
  roleFilterOptions: OptionType[]
  roleFilterValue: OptionType | null
  companyFilterSuggestions: autosuggestSuggestion[]
  companyFilterValue: string | number
  companyFilterSuggestLoading: boolean
  search: string
  timeout: any
  filtered: boolean
  typingDoneDelay: number
  usersCount: number
  limit: number
  offset: number
  usersLength: number
  companySearchResults: company[],
}

const ROLE_FILTER_DEFAULT_VALUE = {
  value: '', label: 'Select role'
}

const STATUS_FILTER_DEFAULT_VALUE = {
  value: '', label: 'Select status'
}

class UserManagement extends React.Component<userManagementProps, userManagementState> {
  constructor(props: userManagementProps) {
    super(props);

    this.state = {
      users: [],
      loaded: false,
      loadedMore: true,
      statusFilterOptions: [STATUS_FILTER_DEFAULT_VALUE],
      statusFilterValue: STATUS_FILTER_DEFAULT_VALUE,
      roleFilterOptions: [ROLE_FILTER_DEFAULT_VALUE],
      roleFilterValue: ROLE_FILTER_DEFAULT_VALUE,
      companyFilterSuggestions: [],
      companyFilterValue: '',
      companyFilterSuggestLoading: false,
      search: '',
      timeout: '',
      filtered: false,
      typingDoneDelay: 600,
      usersCount: 5, // has to be equal to limit, is set @componentDidMount
      limit: 5,
      offset: 0,
      usersLength: 5,
      companySearchResults: [],
    };

    this.handleTeamRequest = this.handleTeamRequest.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onChangeStatusFilter = this.onChangeStatusFilter.bind(this);
    this.onChangeRoleFilter = this.onChangeRoleFilter.bind(this);
    this.onCompanySuggestSearch = this.onCompanySuggestSearch.bind(this);
    this.onCompanySuggestSubmit = this.onCompanySuggestSubmit.bind(this);
    this.onClickResetSearch = this.onClickResetSearch.bind(this);
  }

  componentDidMount(): void {
    this.handleTeamRequest(true);

    // Fill singleSelect with options
    let statusObjects: OptionType[] = [
      STATUS_FILTER_DEFAULT_VALUE
    ];
    Object.keys(UserStatusService.status).forEach((status_) => {
      // @ts-ignore
      let statusLabel = UserStatusService.getLabelforStatus(status_);
      statusObjects.push({value: statusLabel, label: statusLabel})
    })

    let roleObjects: OptionType[] = [
      ROLE_FILTER_DEFAULT_VALUE
    ];
    Object.keys(UserRoleService.roles).forEach((role_) => {
      // @ts-ignore
      let roleLabel = UserRoleService.getRoleLabel(role_);
      roleObjects.push({value: role_, label: roleLabel})
    })

    this.setState({
      statusFilterOptions: statusObjects,
      roleFilterOptions: roleObjects
    })
  }

  handleTeamRequest(initial?: boolean) {
    if (initial) {
      this.setState({
        offset: 0,
        loaded: false
      })
    }
    const params = {
      limit: this.state.limit,
      offset: initial ? 0 : this.state.offset,
    };

    if (this.state.roleFilterValue && this.state.roleFilterValue.value !== '') {
      Object.assign(params, {
        'filter[users][roles]': this.state.roleFilterValue.value
      });
    }

    if (this.state.statusFilterValue && this.state.statusFilterValue.value !== '') {
      Object.assign(params, {
        'filter[status][eq]': this.state.statusFilterValue.value
      });
    }

    if (this.state.companyFilterValue !== '') {
      Object.assign(params, {
        'filter[company][eq]': this.state.companyFilterValue
      });
    }

    let searchTerm = this.state.search.trim();
    if (searchTerm !== '') {
      Object.assign(params, {
        'filter[users][firstName]': searchTerm,
        'filter[users][lastName]': searchTerm,
        'filter[users][email]': searchTerm
      });
    }

    this.context.getUsers(params).then((teamObj: { count: number, data: user[] }) => {
      this.setState({
        users: initial ? teamObj.data : this.state.users.concat(teamObj.data),
        usersLength: teamObj.count,
        loadedMore: true,
        loaded: true
      })
    }, () => {
      this.setState({
        loaded: true,
        loadedMore: true
      })
    });
  }

  setFilter(triggerRequest?: boolean) {
    this.setState({
      users: [],
      filtered: false,
      loaded: false,
      offset: 0,
    }, () => {
      // callback after asynchron state changes needed for select filter changes
      if (triggerRequest)
        this.handleTeamRequest();
    });
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    clearTimeout(this.state.timeout);
    this.setState({
      search: e.target.value,
      timeout: setTimeout(() => {
        this.setFilter(true);
      }, this.state.typingDoneDelay)
    });
  }

  onChangeStatusFilter(value: ValueType<OptionType, false> | null): void {
    this.setState({
      statusFilterValue: value
    }, () => this.setFilter(true));
  }

  onChangeRoleFilter(value: ValueType<OptionType, false> | null): void {
    this.setState({
      roleFilterValue: value
    }, () => this.setFilter(true));
  }

  onClickLoadMore() {
    this.setState({
      loadedMore: false,
      offset: this.state.offset + this.state.limit,
      usersCount: this.state.usersCount + this.state.limit
    }, this.handleTeamRequest);
  }

  getSuggestionsFromCompanies(companies: company[]): autosuggestSuggestion[] {
    let suggestions: autosuggestSuggestion[] = [];
    if (companies.length > 0) {
      companies.forEach(company => {
        suggestions.push({
          id: company.id,
          text: company.name
        })
      });
    }
    return suggestions;
  }

  onCompanySuggestSearch(sWord: string, hasMinCharakters: boolean) {
    if (sWord && hasMinCharakters) {
      this.setState({
        companyFilterSuggestLoading: hasMinCharakters,
        companyFilterSuggestions: []
      }, () => {
        if (hasMinCharakters) {
          this.context.getCompanies({
            'filter[companies][search]': sWord.trim(),
            'limit': 50,
          })
            .then((result: { count: number, data: company[] }) => {
              this.setState({
                companySearchResults: result.data,
                companyFilterSuggestLoading: false,
                companyFilterSuggestions: this.getSuggestionsFromCompanies(result.data)
              }, () => {
                this.setState({
                  companyFilterSuggestLoading: false
                });
              });
            })
            .catch(() => {
              this.setState({companyFilterSuggestLoading: false});
            })
          ;
        }
      });
    }
  }

  onCompanySuggestSubmit(suggestionId?: string | number) {
    this.setState({companyFilterValue: suggestionId ?? ''}, () => this.setFilter(true));
  }

  onClickResetSearch() {
    this.setState({search: ''}, () => this.setFilter(true));
  }

  render() {
    return (
      <div className="teamoverview__wrapper">
        <div id="teamoverview" className="container">
          <h1 className="pagetitle">User management</h1>
          <BackButton to="/">
            Back to home
          </BackButton>
          <div className="contentbox">
            <div className="row small-gutter team__filter__wrapper">
              <div className="col-12 col-md-6 col-lg-3">
                <InputText
                  placeholder="Search"
                  mode="highlighted"
                  onChange={this.onChangeInput}
                  value={this.state.search}
                  onClickReset={this.onClickResetSearch}
                  showReset={!!this.state.search && this.state.search !== ''}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <Select
                  placeholder="Search"
                  menuIsOpen={true}
                  options={this.state.statusFilterOptions}
                  // @ts-ignore
                  onChange={this.onChangeStatusFilter}
                  value={this.state.statusFilterValue}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <Autosuggest
                  loading={this.state.companyFilterSuggestLoading}
                  onSearch={this.onCompanySuggestSearch}
                  onSubmit={this.onCompanySuggestSubmit}
                  suggestions={this.state.companyFilterSuggestions}
                  placeholder="Search for company"
                  minCharacters={3}
                  addResetBtn={true}
                  typingDelay={0}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <Select
                  placeholder="Search"
                  menuIsOpen={true}
                  options={this.state.roleFilterOptions}
                  // @ts-ignore
                  onChange={this.onChangeRoleFilter}
                  value={this.state.roleFilterValue}
                />
              </div>

            </div>
            {this.state.loaded
              ? <>
                {this.state.users.length > 0
                  ? <>
                    {this.state.users.map((user, i_) => {
                      return (
                        <PersonCard updateUser={() => this.handleTeamRequest(true)} user={this.props.user} key={i_}
                                    teamMember={user} title={user.firstName}/>
                      )
                    })}
                    {this.state.users.length > 0 && (
                      this.state.users.length < this.state.usersLength &&
                      <div className="loading__indicator__wrapper">
                        <div onClick={this.onClickLoadMore.bind(this)}>
                          <LoadingIndicator loading={!this.state.loadedMore}
                                            text="Show more"/>
                        </div>
                      </div>
                    )}
                  </>
                  : <h3>No users found.</h3>
                }
              </>
              : <Loader mode="overview"/>
            }
          </div>
        </div>
      </div>
    )
  }
}

UserManagement.contextType = ApiContext;

export default UserManagement;
