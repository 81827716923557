import React, {ChangeEvent, ComponentState,} from "react";
import InputText from "../InputText";
import Button from "../Button";
import person from "../../types/Person";
import user from "../../types/user";
import Loader from "../Loader";
import ApiContext from "../Context/ApiContext";
import StatusActionButtons from "../StatusActionButtons";
import UserRoleService from "../../services/UserRoleService";
import {userRole, userRoleEntry} from "../../types/userRole";
import Tabs from "../Tabs";
import TabContent from "../TabContent";
import TabCompanies from "./TabCompanies";
import {AlertManager, withAlert} from "react-alert";
import {apiResultCountable} from "../Provider/ApiProvider";
import {USER_STATUS_NEW, USER_STATUS_VALIDATED, USER_STATUS_VALIDATION_FAILED} from "../../types/userStatus";
import {EDIT_ACCOUNT_MODE} from "./constants";

type userData = {
  firstName?: string,
  lastName?: string,
  email?: string,
  roles?: string[],
  customerNumber?: number
}

type editAccountProps = {
  onClickCancel: () => void
  user: user
  handleUserUpdate: (initial: boolean) => void
  roles?: string
  loginuser?: user
  editMode: EDIT_ACCOUNT_MODE
  alert: AlertManager
}

type editAccountStates = {
  email?: string
  firstName?: string
  lastName?: string
  status?: string
  customerNumber?: number
  roles?: userRole[] | string[]
  selectableRoles: userRoleEntry[]
  selectableRolesLoading: boolean
  accountCreated: boolean
  person?: person
  isValid: boolean
  loaded: boolean
  statusChangersEnabled: boolean
}

class EditAccount extends React.Component<editAccountProps, editAccountStates> {
  initialUserData: userData = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    customerNumber: 0
  }

  constructor(props: editAccountProps) {
    super(props);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.checkStatusChangeEnabled = this.checkStatusChangeEnabled.bind(this);

    this.initialUserData = {
      firstName: this.props.user?.firstName,
      lastName: this.props.user?.lastName,
      email: this.props.user?.email,
      roles: this.props.user?.roles,
      customerNumber: this.props.user?.customerNumber,
    }

    this.state = {
      email: this.props.user.email,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      status: this.props.user.status,
      customerNumber: this.props.user.customerNumber,
      roles: this.props.user.roles,
      selectableRoles: [],
      selectableRolesLoading: false,
      accountCreated: false,
      isValid: false,
      loaded: true,
      statusChangersEnabled: true
    }
  }

  componentDidMount() {
    if (this.props.loginuser) {
      this.setState({
        selectableRolesLoading: true
      }, () => {
        if (this.props.loginuser) {
          this.context.getUserRoles(this.props.loginuser.id)
            .then((response: apiResultCountable<userRoleEntry>) => {
              this.setState({
                selectableRoles: response.data,
                selectableRolesLoading: false
              });
            })
            .catch(() => {
              this.setState({
                selectableRolesLoading: false
              });
            })
          ;
        }
      });
    }
  }

  checkStatusChangeEnabled() {
    const currentUserData: userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      roles: this.state.roles,
      customerNumber: this.state.customerNumber
    };

    this.setState({
      statusChangersEnabled: JSON.stringify(currentUserData) === JSON.stringify(this.initialUserData)
    })
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [e.target.id]: value
    } as ComponentState, () => {
      this.checkStatusChangeEnabled();
    });
  }

  onChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    let status = this.state.status;
    let roles = this.state.roles;

    if (e && e.target.id === 'status')
      status = e.target.value;

    if (e && e.target.id === 'role')
      roles = [e.target.value];

    this.setState({
      status: status,
      roles: roles
    }, () => {
      this.checkStatusChangeEnabled();
    })
  }

  onClickSubmit(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({
      loaded: false
    })

    const accountInfo: userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      roles: this.state.roles,
      customerNumber: this.state.customerNumber
    };

    this.context.updateUser(this.props.user?.id, accountInfo).then(() => {
      this.setState({
        accountCreated: true,
        loaded: true
      }, () => {
        this.props.handleUserUpdate(true);
        this.setState({
          loaded: true,
        })
      });
    }).catch(() => {
      this.setState({
        loaded: true
      });

      // @ts-ignore
      let popupContainer = document.getElementById('popup-edit-account');
      if (popupContainer)
        popupContainer.scrollTo(0, 0);
    });
  }

  onClickClose() {
    this.props.onClickCancel();
  }

  render() {
    let enableCompanyIdInput = false;
    if (this.props.user?.status === USER_STATUS_NEW || this.props.user?.status === USER_STATUS_VALIDATION_FAILED)
      enableCompanyIdInput = true;

    let companyName = 'No company was found';
    if (this.props.user?.company) {
      companyName = this.props.user.company.name;
    }

    let userRoleValue: userRole = '';
    if (this.state.roles?.length)
      userRoleValue = this.state.roles[0];

    let emailEnabled = false;
    if (this.state.status === USER_STATUS_NEW || this.state.status === USER_STATUS_VALIDATED)
      emailEnabled = true;

    let editUserForm = <form>
      <StatusActionButtons
        disabled={!this.state.statusChangersEnabled}
        editMode={this.props.editMode}
        loginuser={this.props.loginuser}
        user={this.props?.user}
        mode="invert"
      />
      <div className="form-row">
        <div className="form-row--with__label">
          <label className="form__label">Customer number</label>
          <InputText
            name="customerNumber"
            id="customerNumber"
            disabled={!enableCompanyIdInput}
            readOnly={!enableCompanyIdInput}
            onChange={this.onChangeInput}
            value={this.state.customerNumber}
          />
        </div>
        <div className="form-row--with__label">
          <label className="form__label">Company Name</label>
          <InputText disabled={true} readOnly={true} value={companyName}/>
        </div>
      </div>
      <div className="form-row">
        <div className="form-row--with__label">
          <label className="form__label">Role</label>
          <div className="form-group">

            {this.state.selectableRolesLoading
              ? <Loader mode="xs_inverted"/>
              : <select
                value={userRoleValue}
                className="form-control"
                id="role"
                name="role"
                onChange={this.onChangeSelect}
              >
                {this.state.selectableRoles.map((role, index) => {
                  return <option value={role.key} key={index}>{role.name}</option>
                })}
              </select>
            }

          </div>
        </div>

        <div className="form-row--with__label">
          <label className="form__label">Email</label>
          <InputText
            name="user"
            id="email"
            placeholder="E-Mail Adress*"
            type="email"
            disabled={!emailEnabled}
            onChange={this.onChangeInput}
            required
            value={this.state.email}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-row--with__label">
          <label className="form__label">Firstname</label>
          <InputText
            name="user"
            id="firstName"
            placeholder="Firstname*"
            onChange={this.onChangeInput}
            required
            value={this.state.firstName}
          />
        </div>

        <div className="form-row--with__label">
          <label className="form__label">Lastname</label>
          <InputText
            name="user"
            id="lastName"
            placeholder="Lastname*"
            onChange={this.onChangeInput}
            required
            value={this.state.lastName}
          />
        </div>
      </div>

      <div className="form-row form__bottom">
        <Button
          className="cancel__btn"
          type="button"
          onClick={this.props.onClickCancel}
          mode="invert"
        >Cancel</Button>
        <Button className="submit__btn" onClick={this.onClickSubmit}>Update account</Button>
      </div>

    </form>;

    return (
      !this.state.loaded
        ? <Loader className="success" mode="inverted"/>
        : !this.state.accountCreated
          ? <div className="container popup__form">
            <h2 className="popup__form__title">Edit User</h2>
            {this.props.loginuser && this.props.loginuser.roles.some(role => UserRoleService.editUserCompanyRoles.includes(role))
              ? <Tabs activeMobile={true}>
                <TabContent identifier="general" title="General">
                  {editUserForm}
                </TabContent>
                {this.props.user.company ?
                  <TabContent identifier="companies" title="Associated Companies">
                    <TabCompanies user={this.props.user} editCompanyId={this.props.user?.company?.id}/>
                  </TabContent>
                  : <></>
                }
              </Tabs>
              : editUserForm
            }
          </div>
          : <div className="container popup__form">
            <div className="popup__form__confirmation">
              <p>
                The Account for {this.state.firstName} {this.state.lastName} was updated successfully.
              </p>
              <div className="form-row">
                <Button
                  className="cancel__btn"
                  type="button"
                  onClick={this.onClickClose}
                  mode="invert">close</Button>
              </div>
            </div>
          </div>
    );
  }
}

EditAccount.contextType = ApiContext;
export default withAlert<editAccountProps>()(EditAccount);
