import React, {} from 'react';
import faq from "../types/faq";
import user from "../types/user";
import PopUp from "./PopUp";
import EditAccount from "./EditAccount/EditAccount";
import UserStatusService from "../services/UserStatusService";
import {StatusDot, statusDotColor} from "./StatusDot";
import UserRoleService from "../services/UserRoleService";
import {EDIT_ACCOUNT_MODE, EDIT_ACCOUNT_MODE_FULL} from "./EditAccount/constants";

type personCardState = {
  role: string
  showPopup: boolean
  isUserUpdated: boolean
}

type personCardProps = {
  tagline?: string
  title: string
  faq?: faq
  teamMember: user
  user?: user
  updateUser?: (initial: boolean) => void
  mode: EDIT_ACCOUNT_MODE
  onSwitch?: (initial: boolean) => void
}

class PersonCard extends React.Component<personCardProps, personCardState> {
  static defaultProps = {
    mode: EDIT_ACCOUNT_MODE_FULL
  }

  constructor(props: personCardProps) {
    super(props);

    this.state = {
      role: 'user',
      showPopup: false,
      isUserUpdated: false
    };

    this.showPopup = this.showPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount(): void {
    if (this.props.teamMember?.roles) {
      this.setState({
        role: UserRoleService.getRoleLabelsByArray(this.props.teamMember.roles)
      });
    }
  }

  showPopup() {
    this.setState({
      showPopup: true
    });
  }

  closePopup() {
    this.setState({
      showPopup: false
    }, () => {
      if (this.props.updateUser)
        this.props.updateUser(true);
    });
  }

  render() {
    let statusColor: statusDotColor | undefined;
    switch (this.props.teamMember.status) {
      case 'inactive':
      case 'deleted':
        statusColor = 'red';
        break;
      case 'active':
        statusColor = 'green';
        break;
    }

    let companyName = '';
    if (this.props.teamMember.company)
      companyName = this.props.teamMember.company.name;

    return (
      <div className="person__edit__wrapper">
        {!!this.props.updateUser &&
          <div className="absolute__container">
            <PopUp onClickCloseIcon={this.closePopup} showPopup={this.state.showPopup} id="popup-edit-account">
              <EditAccount
                editMode={this.props.mode}
                loginuser={this.props.user}
                handleUserUpdate={() => this.setState({isUserUpdated: true})}
                user={this.props.teamMember}
                onClickCancel={this.closePopup}
              />
            </PopUp>
          </div>
        }
        <div role="button" className="accordion accordion--team">
          <div className="accordion__head">
            <div className={"person__firstcol"}>
              <div className="accordion__title">
                <StatusDot color={statusColor ?? undefined} title={UserStatusService.getLabelforStatus(this.props.teamMember.status)}/>
                <div>
                  <span className="person__title">
                   {this.props.teamMember?.firstName} {this.props.teamMember?.lastName}
                  </span>
                  <span className="person__email">
                     {this.props.teamMember?.email}
                  </span>
                </div>
              </div>
            </div>

            <span className="person__company">
              {companyName
                ? <>{companyName}</>
                : <></>
              }
            </span>

            <span className="person__role">
              {this.state.role}
            </span>

            {(this.props.teamMember?.id !== this.props.user?.id && !!this.props.updateUser)
              ? <span onClick={this.showPopup} className="person__edit__icon"></span>
              : <span className="person__edit__icon disabled"></span>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PersonCard;
